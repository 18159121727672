import React from 'react'
import { useNavigate } from 'react-router-dom'
import data from '../../data/data.js'
import { isToday } from '../../utils/date.js'
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { useEffect } from 'react';
import InApp from 'detect-inapp';
import VoteBtn from './voteBtn.jsx';
import { useState } from 'react';
import { type } from '@testing-library/user-event/dist/type/index.js';



function isFacebookApp() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
  }

  
const VoteArtist = ({ user, userData, artist, _setUserData }) => {
    const [isInapp, setIsInapp] = useState(new InApp(navigator.userAgent || navigator.vendor || window.opera))


    const addAVote = (e, categorie) => {
        e.target.classList.add("locked")

        const tempData = data[0]
        tempData.username = user
        tempData.lastArtist = artist.pathName

        fetch(`${process.env.REACT_APP_BASE_URL}vote/${artist.pathName}/${categorie.id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(tempData)
        })
        .then((response) => response.json())
        .then((response) => {
            _setUserData()

            if (isInapp.browser === "instagram" || isInapp.browser === "safari") {
                window.location = categorie.link
            } else {
                window.open(categorie.link, '_blank');
            }
        })
        .catch((err) => {})
    }

    return (
        <>
            <p className='actionTitle'>Vote pour {artist.name} dans les catégories :</p>
            {artist.categories.map((categorie) => {
                let _isToday = false
                let lastDate = null

                if (userData) {
                    const dates = userData[artist.pathName].votes.find((vote) => {
                        return vote.id === categorie.id;
                    }).dates
                    
                    if (dates.length > 0) {
                        lastDate = new Date(dates[dates.length - 1])

                        if (isToday(lastDate)) {
                            _isToday = true
                        }
                    } else {
                        _isToday = false
                    }
                }
                
                return (
                    <VoteBtn _isToday={_isToday} categorie={categorie} addAVote={addAVote} artist={artist} />
                )
            })}
        </>
    )
}

export default VoteArtist
import React from 'react'
import LogoNMA from '../../assets/img/mainLogo.webp'
import { Link } from 'react-router-dom'

const ArtistIntroduction = ({ userData, artist }) => {
    return (
        <>
            <button onClick={() => window.open("/")}>
                <img className='logoNMA desktop' src={LogoNMA} alt="" />
            </button>

            {
                userData && (
                    <p className='score'>{Object.values(userData[artist.pathName].activations).map((activation) => activation.points).reduce((a, b) => a + b) + userData[artist.pathName].totalPoints + Object.values(userData[artist.pathName].votes).map((activation) => activation.points).reduce((a, b) => a + b)} points</p>
                )
            }

            <p className='introduction'>Vote pour {artist?.name} et tente de gagner</p>
            <p className='lot' dangerouslySetInnerHTML={{ __html: artist.dota }} />
        </>
    )
}

export default ArtistIntroduction
import React, { useState, useEffect } from 'react'
import data from '../../data/data.js'
import artistsData from '../../data/artistsData.js'
import useLocalStorage from '../../hooks/useLS.js'
import { useLocation } from 'react-router-dom'
import ArtistIntroduction from './artistIntroduction.jsx'
import VoteArtist from './voteArtist.jsx'
import LeftPartArtist from './leftPartArtist.jsx'
import ActivationArtist from './activationArtist.jsx'
import Footer from '../../components/footer/index.jsx'
import InApp from 'detect-inapp';
import disconnectIcon from '../../assets/img/logout.svg'

const Artist = ({ getUserData, setIsSubscribed }) => {
    const location = useLocation()
    const [user, setUser] = useLocalStorage("NMA2024user");
    const [userData, setUserData] = useState(null)
    const [isInapp, setIsInapp] = useState(new InApp(navigator.userAgent || navigator.vendor || window.opera))
    const [artist, setArtist] = useState(Object.values(artistsData).find(artist => artist.pathName === location.pathname.slice(1)))

    useEffect(() => {
        _setUserData()
    }, [])

    const _setUserData = async () => {
        const tempUserData = await getUserData(user)
        if (tempUserData !== null) {
            setUserData(tempUserData.Item)
        } else {
        }
    }

    const activateAnArtist = (e, social, link) => {
        const tempData = data[0]
        tempData.username = user
        
        fetch(`${process.env.REACT_APP_BASE_URL}activate/${artist.pathName}/${social}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tempData)
        })
        .then((response) => response.json())
        .then((response) => { 
            _setUserData()

            if (isInapp.browser === "instagram" || isInapp.browser === "safari") {
                window.location = link
            } else {
                window.open(link, '_blank');
            }
        })
        .catch((err) => {})
    }

    useEffect(() => {
        if (artist?.name) {
            window.digitalData={								
                settings:{							
                    reportSuites:"wmg,wmgfr"						
                },							
                page:{							
                    pageInfo:{						
                        pageName:`Warner Music France:NRJ Awards 2023 Vote:Landing:${artist.name}`,					
                        server:"Warner Music France:Site",					
                        platform:"Static HTML",					
                        devTeam:"Firepit"					
                    },						
                    category:{						
                        primaryCategory:"Warner Music France:Landing Page",					
                        pageType:"Landing Page:Vote Redirect"					
                    }						
                },							
                content:{							
                    artist:"Warner Music France",						
                    label:"Warner Music International",						
                    sublabel:"Warner Music France"						
                }							
            }																					
                                            
            document.dispatchEvent(new CustomEvent('SPAPageTriggered'));
        }
    }, [artist?.name])

    return (
        <>
            <section className='unloggedArtistPage'>
            <LeftPartArtist artist={artist} />
            <div className="rightPartArtist stepsLogged">
                <button
                    className="disconnect"
                    onClick={() => {
                        setIsSubscribed(false)
                        setUser('')
                    }}
                >
                    <img src={disconnectIcon} alt="" />
                </button>
                <ArtistIntroduction userData={userData} artist={artist} />
                <VoteArtist _setUserData={_setUserData} user={user} userData={userData} artist={artist} />
                <ActivationArtist _setUserData={_setUserData} user={user} userData={userData} artist={artist} activateAnArtist={activateAnArtist} />
                <Footer disposition='column' />
            </div>
            </section>
        </>
    )
}

export default Artist
function isToday(date) {
    const today = new Date();
  
    if (today.toDateString() === date.toDateString()) {
      return true;
    }
  
    return false;
}

const WEEK_LENGTH = 604800000;

function onCurrentWeek(date) {
  var lastMonday = new Date(); // Creating new date object for today
  lastMonday.setDate(lastMonday.getDate() - (lastMonday.getDay() - 1)); // Setting date to last monday
  lastMonday.setHours(0, 0, 0, 0); // Setting Hour to 00:00:00:00

  const res =
    lastMonday.getTime() <= date.getTime() &&
    date.getTime() < lastMonday.getTime() + WEEK_LENGTH;
  return res; // true / false
}

function getNextMonday(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();
    const todayDay = todayObj.getDay();
  
    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
  
    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 7);
  
    // if date is equal or within the first and last dates of the week
    return lastDayOfWeek;
}

export {
    isToday,
    onCurrentWeek,
    getNextMonday
}


import React from 'react'
import { Link } from 'react-router-dom'
import LogoNMA from '../../assets/img/mainLogo.webp'

const LeftPartArtist = ({ artist }) => {
    return (
      <div className={`leftPartArtist ${artist?.name}`}>
        <h1>{artist?.name}</h1>
        <picture>
          <source srcSet={artist?.picture} media="(min-width: 1025px)" />
          <img src={artist?.pictureMobile} alt="" />
        </picture>
        <Link to="/">
          <img className="logoNMA mobile" src={LogoNMA} alt="" />
        </Link>
      </div>
    );
}

export default LeftPartArtist
const artistsData = {
  amir: {
    name: "Amir",
    url: "amir",
    formID: "41589",
    startDate: "",
    pathName: "amir",
    dota: "une expérience en ULTRA VIP aux NRJ MUSIC AWARDS en sa compagnie !",
    dotaTwi:
      "une expérience en ULTRA VIP aux NRJ MUSIC AWARDS en sa compagnie !",
    categories: [
      {
        name: "Artiste masculin francophone de l'année",
        link: "https://Amir.lnk.to/NMA2024",
        id: "amir-artiste-masculin-francophone",
      },
    ],
    picture: "/assets/img/amir-desktop.png",
    pictureMobile: "/assets/img/amir-mobile.png",
    activations: [
      {
        social: "webapp",
        text: "Partage cette page sur ton réseau social favori",
        url: "https://www.nmawm.com/amir",
        points: 20,
      },
      {
        social: "clip",
        twitterText: "Découvrez l'album « C amir » de Amir",
        text: "Partage l'album « C amir » sur ton réseau social favori",
        url: "https://amir.lnk.to/camir!NMA",
        points: 20,
      },
    ],
  },
};

export default artistsData;

import React, { useState, useEffect, useRef } from 'react'

const VoteBtn = ({ categorie, _isToday, addAVote, artist }) => {
    const comeBackRef = useRef(null)

    return (
        <div className='vote'>
            <p>{categorie.name}</p>
            <p className='points'>+10pts</p>

            <div className="share">
                <button custom-link-name={`${new Date().toISOString().split('T')[0]} Vote`} className={`action ${_isToday === true && 'locked'}`} onClick={(e) => {
                    if (_isToday === false) {
                        addAVote(e, categorie)
                        comeBackRef.current.classList.add('on')
                    }
                }}>Voter</button>
            </div>
            <p ref={comeBackRef} className={`comeBack ${_isToday === true && 'on'}`}>Reviens demain afin de voter à nouveau pour {artist.name}</p>
        </div>
    )
}

export default VoteBtn
import React, { useState, useEffect } from 'react'
import TwitterIcon from '../../assets/img/twitter.svg'
import FacebookIcon from '../../assets/img/facebook.svg'
import {
  getNextMonday,
  onCurrentWeek,
} from "../../utils/date";

const ActivationArtist = ({ artist, userData, activateAnArtist }) => {
    
    return (
        <div className='activations'>
        {artist.activations.map((activation) => {
            return (
                <Activation artist={artist} userData={userData} activation={activation} activateAnArtist={activateAnArtist} />
            )
        })}
        </div>
    )
}

export default ActivationArtist



const Activation = ({ activation, activateAnArtist, userData, artist }) => {
    const [_locked, setLocked] = useState(false)
    const [dates, setDates] = useState(null)

    useEffect(() => {
        if (dates === null) {
            if (userData) {
                setDates(userData[artist.pathName].activations[activation.social].actions)
            }
        }

        if (dates && onCurrentWeek(new Date(dates[dates.length - 1]))) {
          setLocked(true);
        }
    }, [dates, userData])

    if (_locked === true) {
        return (
            <div className='share'>
                <div className='shareBtns'>
                    <button className='action locked'><img src={TwitterIcon} alt='' /> Twitter</button>
                    <button className='action locked'><img src={FacebookIcon} alt='' /> Facebook</button>
                </div>
                
                <p className='comeBack'>Reviens le {("0" + (parseInt(getNextMonday(new Date()).getDate()) + 1)).slice(-2) + "/" + ("0" + (parseInt(getNextMonday(new Date()).getMonth()) + 1)).slice(-2)} pour gagner d'avantage de points</p>
            </div>
        )
    } else {
        let textTwitter
        let fbText

        if (activation.social === "webapp") {
            textTwitter = `https://twitter.com/intent/tweet?text=Vote pour ${artist.name} aux Nrj Music Award et tente de gagner ${artist.dotaTwi} ${activation.url} ${encodeURIComponent('#NMA2024')}`
            fbText = `https://www.facebook.com/sharer/sharer.php?u=${activation.url}`
        } else {
            textTwitter = 'https://twitter.com/intent/tweet?text=' + activation.twitterText + " " + activation.url + ' ' + encodeURIComponent('#NMA2024')
            fbText = `https://www.facebook.com/sharer/sharer.php?u=${activation.url}`
        }

        return (
            <div className='share'>
                <p>{activation.text}</p>
                <p className='points'>+{activation.points}pts</p>
                <div className='shareBtns'>
                    <button className="action twitter-share-button"
                        custom-link-name={`${new Date().toISOString().split('T')[0]} Twitter Share`} onClick={(e) => {
                        setLocked(true)
                        activateAnArtist(e, activation.social, textTwitter)
                    }} target="_blank" data-size="large"><img src={TwitterIcon} alt='' /> Twitter</button>
                    {/* {textTwitter} */}
                    <button
                        custom-link-name={`${new Date().toISOString().split('T')[0]} Facebook Share`}
                        className='action'
                        onClick={(e) => {
                            setLocked(true)
                            activateAnArtist(e, activation.social, fbText)
                        }}
                    >
                        <img src={FacebookIcon} alt='' />Facebook
                    </button>
                </div>
            </div>
        )
    }
}